

function Skills(props) {
  return (
      <div className="skill-section ">
        <div className="skill-left">
            <h1 className={props.hide_header}>Skills</h1>
            <ul className="skill-list">
                <li>
                    <span className="skill-name">Front-end Development: </span>
                    <span className="skill-level mobile-only">High </span>
                </li>
                <li>
                    <span className="skill-name">Website Building: </span>
                    <span className="skill-level mobile-only">High </span>
                </li>
                <li>
                    <span className="skill-name">Troubleshooting: </span>
                    <span className="skill-level mobile-only">High</span>
                </li>
                <li>
                    <span className="skill-name">Content Management Systems (WordPress, Shopify): </span>
                    <span className="skill-level mobile-only">High</span>
                </li>
                <li>
                    <span className="skill-name">Responsive Design: </span>
                    <span className="skill-level mobile-only">Medium</span>
                </li>
                <li>
                    <span className="skill-name">HTML, CSS, JavaScript: </span>
                    <span className="skill-level mobile-only">High</span>
                </li>
                <li>
                    <span className="skill-name">UI/UX Design Principles: </span>
                    <span className="skill-level mobile-only">Low</span>
                </li>
                <li>
                    <span className="skill-name">Cross-Browser Compatibility: </span>
                    <span className="skill-level mobile-only">Medium</span>
                </li>
                <li>
                    <span className="skill-name">Performance Optimization: </span>
                    <span className="skill-level mobile-only">Medium</span>
                </li>
                <li>
                    <span className="skill-name">Version Control (Git): </span>
                    <span className="skill-level mobile-only">Medium</span>
                </li>
                <li>
                    <span className="skill-name">Adobe Photoshop, Illustrator, InDesign and XD: </span>
                    <span className="skill-level mobile-only">Low</span>
                </li>
                <li>
                    <span className="skill-name">Google Analytics Setup and Reports: </span>
                    <span className="skill-level mobile-only">Medium</span>
                </li> 
            </ul>
        </div>
        
        <div className="skill-right">
            <h1 className={props.hide_header}>Skill Levels</h1>
            <ul className="skill-list skill-level">
                <li><span>High</span></li>
                <li><span>High</span></li>
                <li><span>High</span></li>
                <li><span>High</span></li>
                <li><span>Medium</span></li>
                <li><span>High</span></li>
                <li><span>Low</span></li>
                <li><span>Medium</span></li>
                <li><span>Medium</span></li>
                <li><span>Medium</span></li>
                <li><span>Low</span></li>
                <li><span>Medium</span></li>
            </ul>
        </div>
      </div>
  );
}

export default Skills;
